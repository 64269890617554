.App {
  height: 100vh;
  background-color: white;
}


button-group button{

  font-size: 18px;
  outline: none;
  border: none;
  border: 'none';
  background: white;
  margin-top: 5px;
  margin-bottom: 5px;
}


button.is-active{
  background: rgb(197,197,197);
}


/* Basic editor styles */
.tiptap {
  text-align: left;
  padding: 10px;
  border: none;
  background-color: #FFFCE1;
  border-radius:10px;
  border:none;
  font-size: 14px;

  .tiptap :first-child {
    margin-top: 0;
  }

  /* List styles */
  .tiptap ul, 
  .tiptap ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    
    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  .tiptap h1 {
    font-size: 18px;
  }

  .tiptap h2 {
    font-size: 16px;
  }
  .tiptap h3
  {
    font-size: 14px;
  }
  .tiptap h4 {
    font-size: 12px;
  }
  .tiptap h5 {
    font-size: 10px;
  }
  .tiptap h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
    font-size: 8px;
  }

  .tiptap h1, 
  .tiptap h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  /* Code and preformatted text styles */
  .tiptap code {
    background-color: #9c9c9c;
    border-radius: 0.4rem;
    color: #17202A;
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  .tiptap pre {
    background: #17202A;
    border-radius: 0.5rem;
    color: #FDFEFE;
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  .tiptap blockquote {
    border-left: 3px solid #9c9c9c;
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  .tiptap hr {
    border: none;
    border-top: 1px solid #9c9c9c;
    margin: 2rem 0;
  }
}


.tiptaptable {
  text-align: center;
  padding: 10px;
  border: none;
  background-color: #FFFCE1;
  border-radius:10px;
  font-size: 14px;

  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul, 
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    
    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }
  h3
  {
    font-size: 14px;
  }
  h4 {
    font-size: 12px;
  }
  h5 {
    font-size: 10px;
  }
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
    font-size: 8px;
  }

  h1, 
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: #9c9c9c;
    border-radius: 0.4rem;
    color: #17202A;
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: #17202A;
    border-radius: 0.5rem;
    color: #FDFEFE;
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid #9c9c9c;
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid #9c9c9c;
    margin: 2rem 0;
  }
}
